import React, { useEffect, useState } from "react";
import "./wheelGameHome.scss"
import { Link } from "react-router-dom";

export default function WheelGameHome(){

    const [display, setDisplay] = useState<boolean>(false)

    useEffect(()=>{
        const checkIfGameActive = async ()=>{
            const isGameActive = await (await fetch("/is-wheel-game-active")).text()
            setDisplay(isGameActive === "yes")
        }
        checkIfGameActive()
    }, [])

    return(
        <div className="wheelGameHome" style={{display: display ? "flex" : "none"}}>

            <img src={require("../images/landingpage/wheel.svg").default} alt=""/>

            <div className="wheelGameHomeContent">
                <h2>Jeu de la roulette</h2>
                <p>
                    <span className="center">Le jeu de la roulette est de retour !</span><br/>
                    À chaque campagne, tentez votre chance afin de gagner un code promotionel.

                    Pour cela, il suffit de vous inscrire et de lancer la roue, que la chance soit à vos côtés !

                </p>
                <Link className="send" to="game">Découvrir</Link>
            </div>

        </div>
    )
}