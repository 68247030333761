import React from "react";

export default function Sponsorship(){

    return(
        <div className="lpgenericdiv sponsorship" id="sponsorship">

            <img src={require("../images/landingpage/network.png")} alt=""/>

            <div className="lpgenericdivContent">
                <h2>Parrainage</h2>

                <img src={require("../images/landingpage/network.png")} alt=""/>

                <p>
                    Rue Canine propose une offre de parrainage qui vous permettra à vous ainsi qu’à vos proches de bénéficier de réductions.<br/><br/>

                    En parrainant vos amis, ils bénéficieront d’une réduction lors de leur premier achat et vous aussi.<br/>
                    Pour parrainer, veuillez suivre les trois étapes suivantes :

                </p>


                <ol>
                    <li>Entrez l’adresse mail de votre contact dans l'espace Mon Compte.</li>
                    <li>Votre contact va recevoir un code lui accordant une <span className="mainColor">réduction de 10%</span> (1) sur son premier achat.</li>
                    <li>Recevez immédiatement un code à la suite du premier achat de votre contact.</li>
                    <li>Tapez le code pour bénéficier d’une <span className="mainColor">réduction à votre tour de 10% </span>sur votre prochaine commande (1).</li>
                </ol>

                <p className="annotation"><br/>(1) Offre promotionnelle non cumulable.</p>
            </div>


        </div>
    )
}