
import React, { useEffect, useState } from "react"
import {useAppSelector } from "../reduxFolder/store"
import "./adminHome.scss"
import { displayAlertModal, setGeneralDataLocal } from "../utils/generalMethods"
import { IWheelGame } from "../utils/generalTypes"
import { randomWheelGame } from "../utils/generalData"

export default function AdminHome(){

    const generalState = useAppSelector(state=>state.general.generalWebsiteData)


    const [bannerMessage, setBannerMessage] = useState<string>(generalState.bannerMessage)
    const [deliveryFee, setDeliveryFee] = useState<number>(generalState.deliveryFee)

    const [wheelGameData, setWheelGameData] = useState<IWheelGame>(randomWheelGame)

    const changeWebsiteGeneralData = async (e:React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
        e.preventDefault()
        const changeDataReq = await (
            await fetch("/admin/modify-general-data", {
                method:"POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({
                    bannerMessage,
                    deliveryFee,
                    wheelGame:wheelGameData
                })
            })
        ).text()
        displayAlertModal(changeDataReq)
        setGeneralDataLocal({bannerMessage, deliveryFee, wheelGame:randomWheelGame})
    }


    useEffect(()=>{
        const getWheelGameData = async ()=>{
            const wheelGameDataReq = await(
                await fetch("/admin/get-wheel-game-data")
            ).text()
            setWheelGameData(JSON.parse(wheelGameDataReq))
        }
        getWheelGameData()
    }, [])

    return(
        <div className="apHome">
            <h2>Accueil</h2>
            <p>
                Bienvenue sur l'accueil du panel administrateur de Rue Canine.<br/>
                Vous pouvez retrouver dans le menu à gauche plusieurs pages qui permettent d'effectuer des actions ou de visualiser différentes choses.
            </p>

            <h3>Informations générales du site</h3>

            <form>
                <input name="generalmessage" type="text" placeholder="Message de bienvenue" value={bannerMessage} onChange={(e)=>{setBannerMessage(e.target.value)}}/>
                <input name="delivery" type="number" placeholder="Frais de livraison" value={deliveryFee} onChange={(e)=>{setDeliveryFee(parseInt(e.target.value, 10))}}/>
                
                <h3>Jeu de la roulette</h3>

                <div className="row checkboxContainer">
                    <label>Actif ?</label>
                    <input type="checkbox" checked={wheelGameData.isActive} onChange={(e)=>{setWheelGameData({...wheelGameData, isActive:e.target.checked})}} />
                </div>
                <input type="number" name="none" placeholder="Nombre maximum de gagnants" value={wheelGameData.maxWinners} onChange={(e)=>{setWheelGameData({...wheelGameData, maxWinners:parseInt(e.target.value, 10)})}} />
                <input type="number" name="none" placeholder="Pourcentage de remise" value={wheelGameData.percent} onChange={(e)=>{setWheelGameData({...wheelGameData, percent:parseInt(e.target.value, 10)})}} />
                <input type="number" name="none" placeholder="Chances sur 100" value={wheelGameData.probability} onChange={(e)=>{setWheelGameData({...wheelGameData, probability:parseInt(e.target.value, 10)})}} />
                
                <button className="send" onClick={(e)=>{changeWebsiteGeneralData(e)}}>Modifier</button>
            </form>

        </div>
    )
}