import React, { useState } from "react";
import { displayAlertModal } from "../utils/generalMethods";

export default function Pros(){

    const [email, setEmail] = useState("")
    const [tel, setTel] = useState("")
    const [company, setCompany] = useState("")


    const sendForm = async ()=>{
        const response = await (await fetch("/pro-contact", {
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                email,
                tel, 
                company
            })
        })).text()

        displayAlertModal(response)
    }


    return(
        <div className="lpgenericdiv pros" id="pros">
            <img src={require("../images/landingpage/handshake.png")} alt=""/>

            <div className="lpgenericdivContent">
                <h2>Vous êtes un professionnel ?</h2>
                <img src={require("../images/landingpage/handshake.png")} alt=""/>


                <p>
                    Vous êtes revendeur ou éleveur et souhaitez distribuer nos produits dans votre boutique en ligne ou votre magasin ?<br/><br/>
                    Contactez-nous, nous reprendrons contact avec vous en moins de 48h.
                </p>

                <form>
                    <input name="email" type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} placeholder="E-mail" />
                    <input name="tel" type="tel" value={tel} onChange={(e)=>{setTel(e.target.value)}} placeholder="Téléphone" />
                    <input name="company" type="text" value={company} onChange={(e)=>{setCompany(e.target.value)}} placeholder="Entreprise" />
                    <button className="send" onClick={(e)=>{e.preventDefault();sendForm()}}>Envoyer</button>
                </form>
            </div>
        </div>
    )
}