export const ADULT_STR = "adult"
export const YOUNG_STR = "young"
export const DOG_STR = "dog"
export const CAT_STR = "cat"
export const FOOD_STR = "food"
export const TREAT_STR = "treat"
export const ACCESSORY_STR = "accessory"
export const ALL_STR = "all"
export const PREMIUM_STR = "premium"
export const SUPERPREMIUM_STR = "superpremium"



export enum Animal{
    Cat=CAT_STR,
    Dog=DOG_STR,
    All=ALL_STR
}

export enum ProductType{
    Food=FOOD_STR,
    Accessory=ACCESSORY_STR,
    Treat=TREAT_STR,
    All=ALL_STR
}

export enum Age{
    Young=YOUNG_STR,
    Adult=ADULT_STR,
    All=ALL_STR
}

export enum LineType{
    Premium=PREMIUM_STR,
    SuperPremium=SUPERPREMIUM_STR,
    All=ALL_STR
}

export interface FoodSelectorChoices{
    animal:Animal;
    age:Age;
    productLine:LineType;
}

export interface ProductLine{
    weight:number;
    price:number;
}

export type Ingredient = "agneau" | "riz" | "lin" | "levure" | "citrouille" | "epluchures" | "pois" | "carotte" | "grenade" | "myrtilles" | "huileolive" | "poulet" | "cassava" | "saumon"

export type ProductDetailTabType = "description" | "composition" | "transition" | "dermasupport" | "everythingabout"

export interface DailyQuantity {
    weight:number;
    range:number[]
}

export interface Product{
    id:string;
    title:string;
    productType:ProductType;
    imgSrc:string;
    age:Age;
    animal:Animal;
    lineType?:LineType
    price?:number;
    weight?:number;
    availableLines?:ProductLine[];
    ingredients?:Ingredient[];
    description:string;
    stockAvailable:boolean;
    hasDermaSupport?:boolean; 
    brandName:string; 
    brandDescription:string;
    stringIngredients?:string;
    nutritionalValues?:string;
    dailyQuantities?:DailyQuantity[],
    stockQuantity:number;
}

export enum BuyingType{
    simpleBuy = "simpleBuy",
    subscription = "subscription"
}

export interface CartItem{
    product:Product;
    quantity:number;
    selectedLineIndex?:number;
}

export interface CartItemServer extends Omit<CartItem, "product">{
    product:string;
}

export interface Order{
    products:CartItem[];
    orderType:BuyingType;
    onGoing?:boolean;
    frequency?:number;
    subscriptionId?:string;
    date:string;
    totalPrice:number;
    paymentInformations?:{
        name:string;
        address:string;
        promoCode:string;
        relayId?:string;
        stripeCardId:string;
    }
}


export interface UserData {
    name:string;
    email:string;
    tel:string;
    address:string;
    postalCode:string;
    city:string;
    previousOrders?:Order[],
    isAdmin:boolean;
}


export interface UserDataServer extends UserData {
    password?:string;
    userId:string;
    latestSubId?:string;
}

export interface IUserRegisterData extends Omit<UserData, "previousOrders" | "isAdmin">{
    password:string;
    confirmPassword:string;
}

export interface ISession{
    user:UserData
}

export interface PaymentInformations{
    name:string;
    address:string;
    cardCode:string;
    expiry:string;
    cvv:string;
    promo:string;
}

export interface IPromoCode{
    code:string;
    percent:number;
    isValid:boolean;
    isGlobal:boolean;
    sponsorship?:{
        fromEmail:string;
        toEmail:string;
        toName:string;
    }
}

export interface IWheelGame{
    isActive:boolean;
    maxWinners:number;
    usersWhoWon:number;
    percent:number;
    probability:number; // x / 100
    usersWhoAlreadyPlayed:string[]; // userIds
}

export interface IGeneralWebsiteData {
    bannerMessage:string;
    deliveryFee:number;
    wheelGame: IWheelGame;
}

export enum AdminPageType{
    Home = "Home",
    Products = "Products",
    Users = "Users",
    Promos = "Promos",
    Orders = "Orders"
}
