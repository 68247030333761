import React, { useEffect, useState } from "react"
import { BuyingType, UserDataServer } from "../utils/generalTypes"
import "./adminUsers.scss"
import "./adminOrders.scss"
import Summary from "./productSummary"
import { displayAlertModal } from "../utils/generalMethods"

export default function AdminUsers(){


    const [users, setUsers] = useState<UserDataServer[]>([])
    const [searchUser, setSearchUser] = useState("")

    const userInfosLabels = ["Nom", "E-mail", "Téléphone", "Adresse", "Ville", "Code postal", "ID Utilisateur"]

    useEffect(()=>{
        const fetchUsers = async ()=>{
            const fetchUsersReq = await (await fetch("/admin/all-Users")).text()
            if(fetchUsersReq.includes("{")){
                const usersObj:UserDataServer[] = JSON.parse(fetchUsersReq)
                setUsers(usersObj)
            }
            else{
                displayAlertModal(fetchUsersReq)
            }
        }
        fetchUsers()
    }, [])

    let filteredUsers:UserDataServer[] = [...users]
    searchUser !== "" ? filteredUsers = filteredUsers.filter(user=>[user.name, user.tel, user.email].join("").toLowerCase().includes(searchUser.toLowerCase())) : filteredUsers.length = 30
    
    

    return(
        <div className="apUsers">
            <h2>Utilisateurs</h2>
            <p>
                Par défaut, seuls les 30 derniers utilisateurs sont affichés. Vous pouvez utiliser la barre de recherche ci-dessous pour retrouver un utilisateur.<br/>
                La recherche peut se faire sur le nom, le téléphone ou le mail.
            </p>
            <input name="search" type="text" placeholder="Nom / Téléphone / Mail" value={searchUser} onChange={(e)=>{setSearchUser(e.target.value)}} />

            {
                filteredUsers.length ? 
                    filteredUsers.map((user, index)=>{
                        return (
                            <div className="apUserItem" key={index}>
                                <div className="apUserItemInfos">
                                    {
                                        [user.name, user.email, user.tel, user.address, user.city, user.postalCode, user.userId].map((info, index)=>{
                                            return (
                                                <div className="row" key={index}>
                                                    <h5>{userInfosLabels[index]}</h5>
                                                    <h5>{info}</h5>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    user.previousOrders ? 
                                        <div className="previousOrdersList">
                                            <h3>Commandes passées</h3>
                                            <div className="apOrders"> {/* Using the same code as adminOrders.tsx */}
                                                {
                                                    user.previousOrders.map((order, index)=>{
                                                        return(
                                                            <div key={index} className="apOrderItem">
                                                                <div className="apOrderTitle">
                                                                    <h4>{order.date} - {order.orderType === BuyingType.simpleBuy ? "Achat simple" : `Abonnement (${order.onGoing ? "actif":"inactif"})`} - {order.paymentInformations?.relayId ? "Livraison relais " + order.paymentInformations.relayId : "Livraison à domicile"}</h4>
                                                                    <h4>{order.totalPrice} €</h4>
                                                                </div>
                                                                <div className="summary">
                                                                <Summary productList={order.products} />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    : ""
                                }
                            </div>
                        )
                    })
                : ""
            }

        </div>
    )
}