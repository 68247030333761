import React from "react";
import { Animal } from "../utils/generalTypes";
import "./banner.scss"

type backgroundTime = "spring" | "summer" | "autumn" | "winter" | "christmas" | "halloween"

export default function Banner({setAnimal}:{setAnimal:React.Dispatch<React.SetStateAction<Animal>>}){

    const date = new Date()
    const day =  date.getDate()
    const month:number = date.getMonth() + 1

    let backgroundToUse:backgroundTime = "spring"
    
    if(month >= 3 && month <= 5){
        backgroundToUse = "spring"
    }
    else if(month >= 6 && month <= 8){
        backgroundToUse = "summer"
    }
    else if(month >= 9 && month <= 11){
        backgroundToUse = "autumn"
    }
    else if(month <= 2 || month > 11){
        backgroundToUse = "winter"
    }

    if(month === 10 && (day >= 15 && day <= 31)){
        backgroundToUse = "halloween"
    }

    if(month === 12 && (day >= 10 && day <= 31)){
        backgroundToUse = "christmas"
    }


    
    return(
        <div id="Banner" className={backgroundToUse}>
            <div className="bannerContainer">

                <div className="bannerRowContainer">

                    <img className="bannerImage" src={require("../images/banner/banner-croquettes.png")} alt=""/>

                    <div className="bannerContent">
                        <h1>
                            Des croquettes, friandises et accessoires de qualité pour chiens et chats, sans se ruiner.<br/>
                            En simple achat ou en abonnement.
                        </h1>

                        <img className="bannerImage" src={require("../images/banner/banner-croquettes.png")} alt=""/>

                        
                        <div id="frenchFlag">
                            <div className="blue"></div>
                            <div className="white"></div>
                            <div className="red"></div>
                        </div>

                        <div id="AnimalSelector">
                            <a className="animal" href="#FoodSelector" onClick={(e)=>{ setAnimal(Animal.Dog)}}>
                                <img src={require("../images/quizz/dog-choice.png")} alt=""/>
                                <p>Chien</p>
                            </a>

                            <a className="animal" href="#FoodSelector" onClick={(e)=>{ setAnimal(Animal.Cat)}} >
                                <img src={require("../images/quizz/cat-choice.png")} alt=""/>
                                <p>Chat</p>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="arrowContainer">
                    <div className="arrowLeft"></div>
                    <div className="arrowRight"></div>
                </div>

            </div>
        </div>
    )
}