import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../pages/paymentPage.scss"
import "./menu.scss"
import { useAppSelector } from "../reduxFolder/store";
import { CartItem } from "../utils/generalTypes";
import { setCartItemsLocal } from "../utils/generalMethods";

export default function Menu(){

    const [displayMenu, setDisplayMenu] = useState<boolean>(false)
    const [displayCart, setDisplayCart] = useState<boolean>(false)

    const goTo = useNavigate()
    const location = useLocation()
    const state = useAppSelector(state=>state.general)

    const navigate = (page:string, fromPayment?:boolean)=>{
        if(displayCart === true){
            setDisplayCart(false)
        }
        if(displayMenu === true){
            setDisplayMenu(false)
        }
        goTo(page, {state:{fromPayment}})
    }

    const menuItems = location.pathname === "/" || location.pathname.includes("/#") ? [
        {
            label:"NOS ENGAGEMENTS",
            path:"/#commitments"
        },
        {
            label:"COMMENT ÇA MARCHE",
            path:"/#tutorial"
        },
        {
            label:"TROUVEZ VOS CROQUETTES",
            path:"/#FoodSelector"
        },
        {
            label:"PARRAINAGE",
            path:"/#sponsorship"
        },
/*         {
            label:"RÉDUCTIONS",
            path:"/#promos"
        }, */
        {
            label:"VOUS ÊTES UN PROFESSIONNEL ?",
            path:"/#pros"
        }
    ] : undefined
    

    useEffect(()=>{
        const getCart = async ()=>{
            const cart:CartItem[] = JSON.parse(await (await fetch("/cart/get-cart")).text()).cart
            setCartItemsLocal(cart)
        }
        getCart()
    }, [])


    const deleteCartItem = async (cartItem:CartItem)=>{
        const deleteItemReq = await (
            await fetch("/cart/remove-cart-item", {
                method:"POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({
                    product:cartItem.product.id
                })
            })
        ).text()
        setCartItemsLocal(JSON.parse(deleteItemReq).cart)
        setDisplayCart(false)
    }



    return(
        <div id="menu">

            <div className="topMenuContainer">
                <div className="logo" onClick={()=>{navigate("/")}}>
                    <img src={require("../images/logo.png")} alt="Rue canine"/>
                </div>

                <div className="menuRightContainer">
                    <button className="menuIconButton" style={{display:state.isLoggedin ? "block":"none"}} onClick={()=>{navigate("/account")}}><img src={require("../images/menu/user-2.png")} alt="Compte" /></button>
                    <button className="menuIconButton" style={{display:state.cart && state.cart.length ? "block":"none"}} onClick={()=>{setDisplayCart(!displayCart)}}><img src={require("../images/menu/carticon.png")} alt="Panier" /></button>
                    <button className="menuButton" onClick={()=>{setDisplayMenu(!displayMenu)}}>{"="}</button>
                </div>
            </div>

            <div className={"pageMenu "+ (displayMenu ? "visible" : "hidden")}>
                <a href="/" onClick={(e)=>{e.preventDefault(); navigate("/")}}>ACCUEIL</a>
                <a href="/" onClick={(e)=>{e.preventDefault(); navigate("/presentation")}}>PRÉSENTATION</a>
                <a href="/products/get-products/food/all/all/premium" >GAMME MYFOOD</a>
                <a href="/products/get-products/food/all/all/superpremium">GAMME KLICKER</a>
                <a href="/products/get-products/treat/all/all/all">FRIANDISES</a>
                <a href="/products/get-products/accessory/all/all/premium">ACCESSOIRES</a>
                <a href="/products/get-products/accessory/all/all/superpremium">ACCESSOIRES PRESTIGE</a>
                
                {
                    menuItems ? 
                    menuItems.map((menuItem, index)=>{
                        return <a key={index} href={menuItem.path}>{menuItem.label}</a>
                    }) : ""
                }            
                {state.isLoggedin ? <a href="/#b" onClick={(e)=>{e.preventDefault(); navigate("/account")}} >VOTRE COMPTE</a> : <a href="/#b" onClick={(e)=>{e.preventDefault(); navigate("/login")}} >CONNEXION</a>}
                {state.isLoggedin && state.userData.isAdmin ? <a href="/#b" onClick={(e)=>{e.preventDefault(); navigate("/admin")}} >ADMIN</a> : ""}

                <a href="/" onClick={(e)=>{e.preventDefault(); navigate("/cgv")}}>MENTIONS LÉGALES ET CGV</a>
                <a href="/" onClick={(e)=>{e.preventDefault(); navigate("/privacy")}}>VIE PRIVÉE</a>
            </div>

            <div className={"summaryContainer "+ (displayCart ? "visible" : "hidden")}>
                <h4>Votre panier</h4>
                {
                    state.cart && state.cart.length ? state.cart.map((cartItem, index)=>{
                        return(
                        <div className="cartItem row" key={index}>
                            <img src={`/get-image${cartItem.product.imgSrc}`} alt=""/>
                            <div className="cartItemInfos">
                                <h5>{cartItem.product.brandName}</h5>
                                <div className="row">
                                    <p style={{display:cartItem.product.availableLines ? "block":"none"}}>{cartItem.quantity} x {cartItem.product.availableLines?.[cartItem.selectedLineIndex ?? 0].weight ?? 0} kg</p>
                                    <p>{cartItem.quantity * (cartItem.product.availableLines?.[cartItem.selectedLineIndex ?? 0].price ?? cartItem.product.price ?? 0)} €</p>
                                </div>
                            </div>
                            <button onClick={()=>{deleteCartItem(cartItem)}} className="deleteItem">x</button>
                        </div>
                    )}) : ""
                }
                <button className="send" onClick={()=>{state.isLoggedin ? navigate("/cart") : navigate("/login", true)}}>Commander</button>
            </div>

        </div>
    )
}