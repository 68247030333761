import React, { useEffect, useState } from "react";
import "./wheelGame.scss"
import { useAppSelector } from "../reduxFolder/store";
import { UserData, UserDataServer } from "../utils/generalTypes";
import { useNavigate } from "react-router-dom";

export default function WheelGame(){

    const isMobile = window.screen.width <= 800;

    const [message, setMessage] = useState<string>("Bravo ! Vous avez gagné ! Votre code de promotion est f4er84fe.")
    const [isOverlayVisible, setIsOverlayVisible] = useState<boolean>(false)
    const [isWheelTurning, setIsWheelTurning] = useState<boolean>(false)
    const [canPlay, setCanPlay] = useState<boolean>(false)

    const isLoggedin = useAppSelector(state=>state.general.isLoggedin)
    const navigate = useNavigate()

    const play = async ()=>{
        if(isWheelTurning){
            setIsWheelTurning(false)
        }
        setIsWheelTurning(true)

        setTimeout(()=>{
            setIsOverlayVisible(true)
        }, isMobile ? 5000 : 7000)

        const message = await (await fetch("/user/play-wheel-game")).text()
        setMessage(message)
    }

    const goToLoginPage = ()=>{
        navigate("/login", {state:{fromWheelGame:true}})
    }

    useEffect(()=>{
        const checkIfCanPlay = async ()=>{
            const isGameActive = await (await fetch("/is-wheel-game-active")).text()
            if(isGameActive === "no"){
                navigate("/")
            }
            else{
                const fetchCanPlay = await (await fetch("/user/can-play-wheel-game")).text()
                setCanPlay(fetchCanPlay === "yes")
            }
        }
        
        if(isLoggedin){
            checkIfCanPlay()
        }
    }, [])

    if(isMobile){
        return(
            <div className="wheelGame">
                <div className="wheelGameRowContainer">
                    <div className="wheelGameContent">
                        <h2>Roule ta chance</h2>
                        <p>
                        Rue Canine vous offre la première semaine de chaque mois l’opportunité d’avoir des remises supplémentaires pour vos futurs achats. <br/><br/>
    
                        Une remise de -5% valable sur l’ensemble de notre site internet, votre satisfaction est la plus grande priorité de Rue Canine.<br/><br/>
                        
                        Si vous gagnez, le code de promotion vous sera également envoyé par mail.<br/><br/>
                        <span className="bold">{isLoggedin ? canPlay ? "Cliquez ci-dessous pour faire tourner la roulette participation gratuite" : "Vous avez déjà tenté votre chance. Pas d'inquiétude, le jeu revient très souvent " : "Veuillez vous connecter pour jouer."}</span>
                        <br/>
                        </p>
                        <div className="imgContainer">
                            <div className={"imgOverlay " + (isOverlayVisible ? "visible" : "")}>
                                <p>{message}</p>
                            </div>
                            <img className={isWheelTurning ? "turning" : ""} src={require("../images/landingpage/wheel.svg").default} alt=""/>
                        </div>
                        <button disabled={isLoggedin ? !canPlay : false} className="send" onClick={isLoggedin ? play : goToLoginPage}>{isLoggedin ? "Lancer la roue" : "Se connecter"}</button>
                    </div>
                </div>
            </div>
        )
    }
    else{
        return(
            <div className="wheelGame">
                <div className="wheelGameRowContainer">
                    <div className="imgContainer">
                        <div className={"imgOverlay " + (isOverlayVisible ? "visible" : "")}>
                            <p>{message}</p>
                        </div>
                        <img className={isWheelTurning ? "turning" : ""} src={require("../images/landingpage/wheel.svg").default} alt=""/>
                    </div>
                    <div className="wheelGameContent">
                        <h2>Roule ta chance</h2>
                        <p>
                        Rue Canine vous offre la première semaine de chaque mois l’opportunité d’avoir des remises supplémentaires pour vos futurs achats. <br/><br/>
    
                        Une remise de -5% valable sur l’ensemble de notre site internet, votre satisfaction est la plus grande priorité de Rue Canine.<br/><br/>
                        
                        Si vous gagnez, le code de promotion vous sera également envoyé par mail.<br/><br/>
                        <span className="bold">{isLoggedin ? canPlay ? "Cliquez ci-dessous pour faire tourner la roulette participation gratuite" : "Vous avez déjà tenté votre chance. Pas d'inquiétude, le jeu revient très souvent " : "Veuillez vous connecter pour jouer."}</span>
                        <br/>
                        </p>
                        <button disabled={isLoggedin ? !canPlay : false} className="send" onClick={isLoggedin ? play : goToLoginPage}>{isLoggedin ? "Lancer la roue" : "Se connecter"}</button>
                    </div>
                </div>
            </div>
        )
    }
}