import { RouterProvider, createBrowserRouter, Outlet } from "react-router-dom";
import ProductsList from "./productsList";
import ProductDetail from "../components/productDetail";
import Menu from "../components/menu";
import LandingPage from "./landingPage";
import LoginPage from "./loginPage";
import PaymentPage from "./paymentPage";
import Account from "./account";
import PaymentDonePage from "./paymentDonePage";
import AlertModal from "../components/alertModal";
import Presentation from "./presentationPage";
import CGVPage from "./cgvPage";
import PrivacyPage from "./privacyPage";
import AdminPanel from "./adminPanel";
import WheelGame from "./wheelGame";

export default function GeneralPage(){

    const Layout = ()=>{
        return(
            <>
                <Menu/>
                <AlertModal/>
                <Outlet/>
            </>
        )
    }


    const router = createBrowserRouter([
        {
            element:<Layout/>,
            children:[
                {
                    path:"/",
                    Component:LandingPage
                },
                {
                    path:"/products/get-products/:productType/:animal/:age/:productLine",
                    Component:ProductsList
                },
                {
                    path:"/products/product-detail/:id",
                    Component:ProductDetail
                },
                {
                    path:"/cart",
                    Component:PaymentPage
                },
                {
                    path:"/payment-done",
                    Component:PaymentDonePage
                },
                {
                    path:"/login",
                    Component:LoginPage
                },
                {
                    path:"/account",
                    Component:Account
                },
                {
                    path:"/presentation",
                    Component:Presentation
                },
                {
                    path:"/cgv",
                    Component:CGVPage
                },
                {
                    path:"/privacy",
                    Component:PrivacyPage
                },
                {
                    path:"admin",
                    Component:AdminPanel
                },
                {
                    path:"game",
                    Component:WheelGame
                }
            ]
        }
    ])

    return(
        <RouterProvider router={router}/>
    )
    


    
}