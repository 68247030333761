import React from "react";
import "./tutorial.scss"

export default function Tutorial(){
    return(
        <div id="tutorial">
            <h2>COMMENT ÇA MARCHE</h2>
            <div className="tutorialStep">
                <h3>1</h3>
                <p>Utilisez le quiz ci-dessous afin de choisir ce qu'il vous faut.</p>
            </div>

            <div className="tutorialStep">
                <h3>2</h3>
                <p>
                    Choisissez les croquettes qui vous correspondent dans la nouvelle page qui s'affiche et sélectionnez le poids de sac qui vous correspond. 
                    Des échantillons sont disponibles selon les produits.
                </p>
            </div>

            <div className="tutorialStep">
                <h3>3</h3>
                <p>
                    Vous pouvez choisir entre un achat unique de croquettes ou bien de vous abonner afin de recevoir des croquettes selon une
                    fréquence que vous aurez choisi.
                </p>
            </div>

            <div className="tutorialStep">
                <h3>4</h3>
                <p>
                    Procédez au paiement, après quoi vous recevrez une facture ainsi qu'un lien de suivi pour votre commande.
                </p>
            </div>
        </div>
    )
}